<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  src="@/assets/images/sihot.png"
                  contain
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar dark flat>
            <v-toolbar-title>Sihot Schnittstelle</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="$refs.tabGeneral.getSettings()">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <v-tabs>
            <v-tab>{{ $t('settings.lang_settings') }}</v-tab>
            <v-tab>{{ $t('settings.lang_dailyReport') }}</v-tab>
            <v-tab>{{ $t('settings.lang_client_outletAssignment') }}</v-tab>
            <v-tab>{{ $t('settings.lang_cashierIdAssignment') }}</v-tab>

            <v-tab-item>
              <SihotSettingsGeneral ref="tabGeneral"></SihotSettingsGeneral>
            </v-tab-item>

            <v-tab-item>
              <SihotDayClose></SihotDayClose>
            </v-tab-item>

            <v-tab-item>
              <SihotOutlets></SihotOutlets>
            </v-tab-item>

            <v-tab-item>
              <SihotCashiers></SihotCashiers>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import SihotSettingsGeneral from "@/components/settings/moduleSettings/SihotSettingsGeneral";
import SihotDayClose from "@/components/settings/moduleSettings/SihotDayClose";
import SihotOutlets from "@/components/settings/moduleSettings/SihotOutlets";
import SihotCashiers from "@/components/settings/moduleSettings/SihotCashiers";

export default {
  name: "SihotSettings",

  components: {SihotOutlets,SihotCashiers, SihotDayClose, SihotSettingsGeneral}
}
</script>