<template>
  <v-card-text>
    <v-row>
      <v-col cols="12">
        <v-btn color="success" @click="doDayClose" :loading="loading" :disabled="loading">{{ $t('settings.lang_carryOutDailyClosing') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- MESSAGE -->
    <v-dialog :value="errorMessage.length > 0" persistent max-width="500">
      <v-card>
        <v-card-text class="text-center" style="font-size: 20px; padding-top: 30px;">{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "SihotDayClose",

  data() {
    return {
      loading: false,
      errorMessage: ""
    }
  },

  methods: {
    doDayClose() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SIHOT.DAYCLOSE).then((res) => {
        this.loading = false

        if (!res.data.success) {
          if (res.data.hasOwnProperty("msg"))
            this.errorMessage = res.data.msg;
          else
            this.errorMessage = this.$t('generic.lang_errorOccurred') + ": "+res.data.error.code+": "+res.data.error.msg;
        } else {
          this.errorMessage = this.$t('settings.lang_endOfDayCompletedSuccessfully')
        }
      }).finally(() => this.loading = false).catch(() => this.loading = false);
    }
  }
}
</script>