<template>
  <div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="saveOutlets" :disabled="loading" :loading="loading">{{ $t('generic.lang_save') }}</v-btn>
    </v-card-actions>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-alert color="primary" dark>{{ $t('settings.lang_sihotOutletsAlertText') }}</v-alert>

          <v-simple-table v-if="!loading">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left" style="max-width: 50px;">{{ $t('generic.lang_cashierID') }} 3POS</th>
                <th class="text-left" style="max-width: 50px;">{{ $t('generic.lang_outletID') }} Sihot</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cashier) in cashierids" :key="cashier.id">
                <td style="max-width: 50px;">{{ $t('generic.lang_cashierID') }} {{ cashier.id }}</td>
                <td style="max-width: 50px;">
                  <v-text-field style="margin-top: 10px;" v-model="cashier.outlet" dense outlined type="number"></v-text-field>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-else class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="saveOutlets" :disabled="loading" :loading="loading">{{ $t('generic.lang_save') }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "SihotOutlets",

  data() {
    return {
      loading: false,

      cashierids: []
    }
  },

  mounted() {
    this.getCashiers();
  },

  methods: {
    getCashiers() {
      this.loading = true;
      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        res.data.cashierIDs.forEach((cashierid) => {
          this.cashierids.push({
            id: cashierid[0],
            outlet: 0
          });
        });

        this.getCashierOutlets();
      }).finally(() => this.loading = false);
    },
    getCashierOutlets() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SIHOT.OUTLETS.GET).then((res) => {
        if(res.data.outlets !== null)
          res.data.outlets.forEach((cashierid) => {
            let outletCashier = this.cashierids.find((cashieridToFind) => cashieridToFind.id === cashierid.id);

            if(outletCashier)
              outletCashier.outlet = cashierid.outlet;
          });
      }).finally(() => this.loading = false)
    },
    saveOutlets() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SIHOT.OUTLETS.UPDATE, {
        outlets: this.cashierids
      }).finally(() => this.loading = false)
    }
  }
}
</script>