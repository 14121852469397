<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_apiHost')" :placeholder="apiHost" v-model="apiHost"></v-text-field>
          <v-text-field :disabled="loading" outlined dense :label="$t('settings.lang_apiKey')" :placeholder="apiKey" v-model="apiKey"></v-text-field>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <p><b>{{ $t('settings.lang_dailyClosingAccounts') }}</b></p>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" style="max-width: 50px;">{{ $t('generic.lang_day') }}</th>
            <th class="text-left" style="max-width: 50px;">{{ $t('accounting.langAccountingAccount') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(account, day) in dayCloseAccounts" :key="day">
            <td style="max-width: 50px;">{{ getDayName(parseInt(day)) }}</td>
            <td style="max-width: 50px;">
              <v-text-field style="margin-top: 10px;" :disabled="loading" v-model="dayCloseAccounts[day]" dense outlined type="number"></v-text-field>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="saveSettings" :disabled="loading" :loading="loading">{{ $t('generic.lang_save') }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "SihotSettingsGeneral",

  data() {
    return {
      loading: false,

      apiHost: "",
      apiKey: "",
      dayCloseAccounts: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: ""
      }
    }
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    getDayName(day) {
      if(day === 1) return this.$t('generic.Montag')
      if(day === 2) return this.$t('generic.Dienstag')
      if(day === 3) return this.$t('generic.Mittwoch')
      if(day === 4) return this.$t('generic.Donnerstag')
      if(day === 5) return this.$t('generic.Freitag')
      if(day === 6) return this.$t('generic.Samstag')
      if(day === 7) return this.$t('generic.Sonntag')
    },
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SIHOT.SETTINGS.GET).then((res) => {
        if(res.data.success) {
          this.apiHost = res.data.settings.apiHost;
          this.apiKey = res.data.settings.apiKey;

          if(res.data.settings.dayCloseAccounts !== null)
            this.dayCloseAccounts = res.data.settings.dayCloseAccounts;
        }
      }).finally(() => this.loading = false);
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SIHOT.SETTINGS.UPDATE, {
        apiHost: this.apiHost,
        apiKey: this.apiKey,
        dayCloseAccounts: this.dayCloseAccounts
      }).then((res) => {
        if(res.data.success) {

        }
        else {

        }
      }).finally(() => this.loading = false)
    }
  }
}
</script>